import React from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useEffect } from 'react';

function Roshni() {
  const isAuthenticated = localStorage.getItem('authenticated') === 'true';

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="min-h-screen bg-pink-100 flex flex-col items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <h1 className="text-4xl font-bold text-pink-500 mb-4">Special Messages</h1>
        <div className="space-y-4">
          <Link to="/roshni/valentines">
            <div className="p-4 bg-red-100 rounded-lg hover:bg-red-200 transition duration-300">
              <h2 className="text-2xl font-bold text-red-500">Valentine's Day 2025</h2>
              <p className="text-gray-600">Click to view your Valentine's message ❤️</p>
            </div>
          </Link>
          <Link to="/roshni/anniversary">
            <div className="p-4 bg-pink-100 rounded-lg hover:bg-pink-200 transition duration-300">
              <h2 className="text-2xl font-bold text-pink-500">Anniversary 2024</h2>
              <p className="text-gray-600">View our anniversary message 💝</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Roshni;