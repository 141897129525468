import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

function RoshniValentines() {
  const isAuthenticated = localStorage.getItem('authenticated') === 'true';
  const [noCount, setNoCount] = useState(0);
  const [yesPressed, setYesPressed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const noButtonText = [
    "No",
    "Are you sure?",
    "Really sure?",
    "Think again!",
    "Last chance!",
    "Surely not?",
    "You might regret this!",
    "Give it another thought!",
    "Are you absolutely sure?",
    "This could be a mistake!",
    "Have a heart!",
    "Don't be so cold!",
    "Change of heart?",
    "Wouldn't you reconsider?",
    "Is that your final answer?",
    "You're breaking my heart 💔"
  ];

  const memories = [
    {
      title: "First Day I Met You 🌟",
      description: "I still remember walking into class freaking out because I only saw seniors, Mrs.Roberson decides to group us up and you talk to me for the first time (best day of my life). Meeting you was the best thing that ever happened to me. From doing group projects to making paper airplanes in the hallways, I never thought I'd find someone who I'd enjoy being around so much. You're the most amazing person I've ever met, and I'm so grateful I got to know you. I love you more than anything in this world! 💕", // Add your memory here
    },
    {
      title: "Why I Love You 💝",
      description: "The way you look and smile at me makes my heart melt. I love how I can always count on you to make me feel better. Your so beautiful and when I get to see you, all I can think about is how lucky I am to have you in my life. I love holding your face and kissing your soft lips and watch as we both melt away in each other's arms. There is nothing more special than being by your side.", // Add your memory here
    },
    {
      title: "Our Future Together 🏠",
      description: "I dream of a life where I get to wake up next to you, have our dogs running around the house, maybe have a baby or two wake us up in the morning, and grow old together. I can't wait to see where life takes us and I know that our future will be very special. I can't wait to travel with you, explore new places, learn about things we never knew existed, and trust me when I tell you that hearing you talk about fun facts I never even knew of will by the highlight of my trips, having you by my side will be the cherry on top. 🍒", // Add your memory here
    },
    {
      title: "Little Things I Love About You ❤️",
      description: "The way you giggle, the way you look at me with those beautiful eyes, the way you make me feel when you're in my arms, the way we enter our own universe bubble when we are together, feeling like we are the only two people in the world. I love how you make me feel when I'm down and stressed, and how you always know what to say to make me feel better. Words can't describe how much love I have for you Roshni. I hope these small notes gives you a glimpse of how much you mean to me. I love you and I can't wait to see you and spend an awesome weekend trip together in San Antonio. Thank you for being my beautiful girlfriend and my Valentine 😊!", // Add your memory here
    }
  ];

  const getNoButtonText = () => {
    return noCount >= noButtonText.length ? "😢" : noButtonText[noCount];
  };

  const handleNoClick = () => {
    setNoCount(noCount + 1);
    const newX = Math.random() * (window.innerWidth - 100);
    const newY = Math.random() * (window.innerHeight - 100);
    setPosition({ x: newX, y: newY });
  };

  const yesButtonSize = noCount * 20 + 16;

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (yesPressed) {
    return (
      <div className="min-h-screen bg-red-50 flex flex-col items-center justify-center p-6">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-2xl w-full">
          <h1 className="text-4xl font-bold text-red-500 mb-4 animate-bounce">
            Thank You For Being My Valentine! 🎉
          </h1>
          <div className="space-y-8 mt-8">
            {memories.map((memory, index) => (
              <div key={index} className="bg-pink-50 p-6 rounded-lg hover:transform hover:scale-105 transition duration-300">
                <h3 className="text-2xl font-bold text-red-400 mb-3">{memory.title}</h3>
                <p className="text-gray-700 leading-relaxed">{memory.description}</p>
              </div>
            ))}
            <div className="grid grid-cols-2 gap-4 mt-8">
              <div className="p-4 bg-red-100 rounded-lg">
                <p className="text-red-600">💝 My Soulmate</p>
              </div>
              <div className="p-4 bg-pink-100 rounded-lg">
                <p className="text-pink-600">💖 My Everything</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-red-50 flex flex-col items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <h1 className="text-4xl font-bold text-red-500 mb-4">
          To My Beautiful Roshni ❤️
        </h1>
        <p className="text-xl text-gray-700 mb-8">
          Will you be my Valentine?
        </p>
        
        {/* Messages Grid */}
        <div className="grid grid-cols-2 gap-6 mb-8">
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <span className="animate-float text-4xl">🌹</span>
              <p className="text-red-400 text-sm bg-pink-50 p-3 rounded-lg shadow-sm">
                Like this rose, my love for you blooms every day
              </p>
            </div>
            
            <div className="flex items-center gap-3">
              <span className="animate-float animation-delay-4000 text-4xl">💖</span>
              <p className="text-red-400 text-sm bg-pink-50 p-3 rounded-lg shadow-sm">
                Every moment with you is precious
              </p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center gap-3 justify-end">
              <p className="text-red-400 text-sm bg-pink-50 p-3 rounded-lg shadow-sm">
                My heart belongs to you, always and forever
              </p>
              <span className="animate-float animation-delay-2000 text-4xl">💝</span>
            </div>
            
            <div className="flex items-center gap-3 justify-end">
              <p className="text-red-400 text-sm bg-pink-50 p-3 rounded-lg shadow-sm">
                You make my heart skip a beat
              </p>
              <span className="animate-float animation-delay-6000 text-4xl">💗</span>
            </div>
          </div>
        </div>

        <p className="text-sm text-gray-500 italic mb-6 animate-pulse">
          I dare you to press no... 😏
        </p>

        {/* Buttons */}
        <div className="space-y-4">
          <button
            className="bg-green-500 text-white px-8 py-4 rounded-lg text-xl font-bold transition transform hover:scale-110 w-full"
            style={{ fontSize: yesButtonSize }}
            onClick={() => setYesPressed(true)}
          >
            Yes 🥰
          </button>
          
          <button
            className="bg-red-500 text-white px-8 py-4 rounded-lg text-xl font-bold transition transform hover:scale-110"
            style={{
              position: noCount > 0 ? 'absolute' : 'relative',
              left: position.x,
              top: position.y,
            }}
            onClick={handleNoClick}
          >
            {getNoButtonText()}
          </button>
        </div>

        <div className="mt-8 text-gray-600">
          <p>PS: I love you more than anything in this world! 💕</p>
          <p className="mt-2 text-sm">- Your Martin</p>
        </div>
      </div>
    </div>
  );
}

export default RoshniValentines; 